<template>
  <v-dialog
    v-if="valid"
    v-model="value"
    max-width="60%"
    persistent
  >
    <v-card>
      <v-card-title class="headline text-capitalize">
        {{type}} {{title}} ?
      </v-card-title>
      <v-card-text v-html="cancelMessage">
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="red darken-1"
          text
          @click="$emit('input', false)"
        >
          Cancle
        </v-btn>

        <v-btn
          small
          color="success darken-1"
          text
          @click="$emit('input', false), $emit('confirm', title)"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import api from '@/services/api'
export default {
  name: 'ConfirmDialog',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    message: {
      type: String,
      default: 'Are you sure you want to <strong>{{TYPE}}</strong> this <strong>{{TITLE}}</strong> ?',
      required: false
    },
    type: {
      type: String,
      require: false,
      default () {
        return ''
      }
    },
    title: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    valid: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: () => ({
  }),
  computed: {
    cancelMessage: function () {
      if (this.message.includes('{{TYPE}}') && this.message.includes('{{TITLE}}')) {
        const msg = this.message.replace('{{TYPE}}', this.type).replace('{{TITLE}}', this.title)
        return msg
      } else {
        return this.message
      }
    }
  },
  methods: {
  },
  created () {
  }
}
</script>
